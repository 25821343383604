import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  LinearProgress,
} from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MicIcon from '@mui/icons-material/Mic';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { storage, auth, db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { collection, doc, setDoc, serverTimestamp, getDoc, updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { v4 as uuidv4 } from 'uuid';
import * as amplitude from '@amplitude/analytics-browser';
import CtaBanner from '../components/CtaBanner';

const Upload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [audioDuration, setAudioDuration] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [remainingSeconds, setRemainingSeconds] = useState(null);
  const [insufficientCredits, setInsufficientCredits] = useState(false);
  const [buyLoading, setBuyLoading] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const [dragging, setDragging] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    amplitude.track('Upload Page Viewed');

    const fetchRemainingSeconds = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            setRemainingSeconds(userDoc.data().remaining_seconds || 0);
            amplitude.track('Remaining Seconds Fetched', {
              userId: user.uid,
              remainingSeconds: userDoc.data().remaining_seconds || 0,
            });
          }
        } catch (error) {
          console.error('Error fetching remaining seconds:', error);
          amplitude.track('Error Fetching Remaining Seconds', { error: error.message });
        }
      }
    };

    fetchRemainingSeconds();
  }, []);

  const handleFileChange = (file) => {
    if (file) {
      const audio = document.createElement('audio');
      audio.src = URL.createObjectURL(file);
      audio.onloadedmetadata = () => {
        const durationSeconds = Math.round(audio.duration);
        setAudioDuration(durationSeconds);
        setSelectedFile(file);

        amplitude.track('File Selected', {
          fileName: file.name,
          fileType: file.type,
          fileSize: file.size,
          duration: durationSeconds,
        });

        if (remainingSeconds >= durationSeconds) {
          startUpload(file, durationSeconds);
        } else {
          setInsufficientCredits(true);
          amplitude.track('Insufficient Credits for File', {
            fileName: file.name,
            fileType: file.type,
            duration: durationSeconds,
            remainingSeconds,
          });
        }
      };
    } else {
      alert('Խնդրում ենք ընտրել վավեր աուդիո ֆայլ։');
      amplitude.track('Invalid File Type Selected', { fileType: file?.type });
    }
  };

  const startUpload = async (file, durationSeconds) => {
    setUploading(true);
    try {
      const user = auth.currentUser;
      if (!user) {
        alert('Դուք պետք է մուտք գործեք համակարգ, որպեսզի վերբեռնեք ֆայլեր։');
        setUploading(false);
        amplitude.track('Upload Attempt Without Login');
        return;
      }

      const sizeMB = (file.size / (1024 * 1024)).toFixed(2);
      const recordingsRef = collection(db, 'recordings');
      const docRef = doc(recordingsRef);
      const docId = docRef.id;

      await setDoc(docRef, {
        userId: user.uid,
        status: 'uploading',
        createdAt: serverTimestamp(),
        transcription: '',
        duration: durationSeconds,
        fileType: file.type,
        source: 'upload',
        size: sizeMB,
      });

      const fileName = `${docId}-${file.name}`;
      const storageRef = ref(storage, `recordings/${fileName}`);

      const uploadTask = uploadBytesResumable(storageRef, file, {
        customMetadata: {
          uid: user.uid,
          docId: docId,
          duration: durationSeconds.toString(),
        },
      });

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
          amplitude.track('Upload Progress', {
            fileName: file.name,
            progress,
          });
        },
        (error) => {
          console.error('Upload failed:', error);
          alert('Ֆայլի վերբեռնումը ձախողվեց։');
          setUploading(false);
          amplitude.track('Upload Failed', { error: error.message });
        },
        () => {
          setUploading(false);
          setUploadProgress(0);
          setSelectedFile(null);
          setAudioDuration(null);
          amplitude.track('Upload Completed', { fileName: file.name });
          navigate(`/recording-details/${encodeURIComponent(docId)}`);
        }
      );
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploading(false);
      amplitude.track('Error During Upload', { error: error.message });
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    handleFileChange(file);
  };

  const handleCancelUpload = () => {
    setSelectedFile(null);
    setAudioDuration(null);
    setInsufficientCredits(false);
    amplitude.track('Upload Cancelled');
  };

  const handleBuyCredits = async () => {
    setBuyLoading(true);
    const functions = getFunctions();
    const createOrRetrieveStripeCustomer = httpsCallable(functions, 'createOrRetrieveStripeCustomer');
    try {
      const { data } = await createOrRetrieveStripeCustomer();
      amplitude.track('Buy Credits Clicked');
      navigate('/pricing', { state: { clientSecret: data.clientSecret } });
    } catch (error) {
      console.error('Error creating or retrieving Stripe customer:', error);
      amplitude.track('Error During Buy Credits', { error: error.message });
    } finally {
      setBuyLoading(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    handleFileChange(file);
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours ? hours + ' ժ ' : ''}${minutes} րոպե ${secs} վրկ`;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: dragging ? 'rgba(255,255,255,0.8)' : '#f5f5f5',
        paddingTop: '64px',
        padding: 2,
        position: 'relative',
        overflow: 'hidden',
        boxSizing: 'border-box',
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <CtaBanner
        text="Փորձեք վերծանման որակը ձեր ձայնով"
        buttonText="ՓՈՐՁԵԼ ՀԻՄԱ"
        onButtonClick={() => navigate('/record')}
        icon={<MicIcon sx={{ fontSize: { xs: 28, sm: 36 }, color: '#229ED9', flexShrink: 0 }} />}
      />

      {dragging && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10,
          }}
        >
          <Typography variant="h4" color="primary">
            Բաց թողեք վերբեռնելու համար
          </Typography>
        </Box>
      )}

      {!selectedFile && !dragging && (
        <Card sx={{ maxWidth: 500, width: '100%', p: 3, textAlign: 'center', boxShadow: 3 }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Աուդիո ֆայլերի վերծանում
            </Typography>
            <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
              Յուրաքանչյուր աուդիո ֆայլ թույլատրելի է
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body2" sx={{ mb: 2 }}>
              Ձայնագրությունը կարող է լինել 🇦🇲 հայերեն, 🇪🇸իսպաներեն, 🇩🇪գերմաներեն, 🇫🇷ֆրանսերեն, 🇷🇺ռուսերեն, 🇯ապոներեն, 🇮🇷պարսկերեն կամ 🇸🇦արաբերեն:
            </Typography>
            <Typography variant="body2" sx={{ mb: 3 }}>
              Կարող եք ընտրել ֆայլ կամ <b>քաշել և թողնել</b> այս պատուհանում:
            </Typography>
            <Button variant="contained" component="label" sx={{ paddingX: 5, paddingY: 2 }}>
              Ընտրել ֆայլ
              <input type="file" hidden onChange={handleFileSelect} />
            </Button>
          </CardContent>
        </Card>
      )}

      {selectedFile && (
        <Card sx={{ maxWidth: 500, width: '100%', p: 2, textAlign: 'center' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Ընտրված ֆայլի տվյալներ
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2">Անուն:</Typography>
                <Typography variant="subtitle2">{selectedFile.name}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Չափս:</Typography>
                <Typography variant="subtitle2">{(selectedFile.size / (1024 * 1024)).toFixed(2)} MB</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Ֆայլի տևողություն:</Typography>
                <Typography variant="subtitle2">{formatTime(audioDuration)}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Հասանելի ժամանակ:</Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ color: insufficientCredits ? 'error.main' : 'text.primary' }}
                >
                  {formatTime(remainingSeconds)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">Ֆայլի ֆորմատ:</Typography>
                <Typography variant="subtitle2">{selectedFile.type}</Typography>
              </Grid>
            </Grid>
          </CardContent>
          <Divider sx={{ my: 2 }} />
          {insufficientCredits && (
            <Typography variant="body2" color="error" sx={{ mb: 2, textAlign: 'center' }}>
              Այս ֆայլը վերծանելու համար անհրաժեշտ է գնել ավել վերծանման ժամանակ
            </Typography>
          )}
          <Box display="flex" justifyContent="space-around" alignItems="center" sx={{ mt: 2 }}>
            {insufficientCredits ? (
              <Button
                variant="contained"
                sx={{
                  paddingX: 4,
                  paddingY: 1.5,
                  backgroundColor: '#ff5722',
                  color: '#fff',
                  '&:hover': { backgroundColor: '#e64a19' },
                }}
                onClick={handleBuyCredits}
                disabled={buyLoading}
                startIcon={buyLoading ? <CircularProgress size={24} color="inherit" /> : <CreditCardIcon />}
              >
                Գնել ժամանակ
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{ paddingX: 4, paddingY: 1.5, width: '45%' }}
                onClick={() => startUpload(selectedFile, audioDuration)}
                disabled={uploading}
              >
                {uploading ? 'Վերբեռնում...' : 'Վերբեռնել'}
              </Button>
            )}
            <Button
              variant="outlined"
              sx={{ paddingX: 4, paddingY: 1.5, width: '45%', ml: 2 }}
              onClick={handleCancelUpload}
              disabled={uploading}
            >
              Չեղարկել
            </Button>
          </Box>

          {uploading && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
              <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
                Վերբեռնում {Math.round(uploadProgress)}%
              </Typography>
            </Box>
          )}
        </Card>
      )}

      <Dialog open={confirmClose} onClose={() => setConfirmClose(false)}>
        <DialogTitle>Վերբեռնումը կչեղարկվի</DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmClose(false)}>Չեղարկել</Button>
          <Button
            onClick={() => {
              setConfirmClose(false);
              window.close();
            }}
            color="error"
          >
            Փակել
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Upload;
